@media only screen and (max-width: 768px) {

    /* Header */

    .main_container {
        width: 100%;
        height: 100%;
    }

    .header_container {
        background-color: rgb(220,255,218);
        height: 3.5rem;
        margin-top: -1.5rem;
    }

    .header_text {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        padding: 1rem;
    }

    /* First Section */

    .container {
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .menu_container {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 100px 100px 0px 0px;
        cursor: pointer;
    }

    .menu_name {
        margin: 0;
        color: gray;
        border-bottom: none;
    }

    .menu_container.active .menu_name {
        color: initial;
        border-bottom: 3px solid #000000;
        padding-bottom: 10px;
    }
    
    .divider {
        margin-top: 1px;
    }

    /* Second Section */

    .second_main_container {
        padding: 10px 15px;
    }

    .heading {
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 16px;
    }

    .text {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        margin-top: -10px;
    }

    /* Third Section */

    .third_main_container {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    .third_header_container {
        display: flex;
        gap: 1rem;
        margin-top: 2rem;
    }

    .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
    }

    .icon_container {
        margin-top: 17px;
        font-size: 20px;
        color: #DFA536;
    }

    .box_container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        height: auto;
        margin-top: 10px;
        margin-bottom: 1rem;
    }

    .my-table {
        width: 100%;
        border-collapse: collapse;
    }

    .my-table th,
    .my-table td {
        padding: 10px;
        text-align: left;
    }

    .name {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .table_row {
        font-size: 13px;
        margin-top: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    .table_head {
        display: flex;
    }

    th {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
    }
        
    td {
        text-align: center;
    }

    /* Popup */

    .popup {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: #fff;
    }

    .points {
        display: flex;
        gap: 1rem;
        align-items: center;
        height: 28px;
        font-size: 14px;
    }

    .popup_header_container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0px 15px;
    }

    .popup_header {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
    }

    .points_main {
        padding: 0px 15px;
    }

    .cross_icon {
        margin-top: 1rem;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 18px;
        padding: 5px 7px;
        height: 20px;
        color: rgba(0, 0, 0, 0.3);
    }
}